import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const navigation = [
  { name: 'Home', href: '/', cta: false },
  { name: 'About Us', href: '/about', cta: false },
  { name: 'Boarding', href: '/boarding', cta: false },
  { name: 'The Arena', href: '/the-arena', cta: false },
  { name: 'Stalls', href: '/stalls', cta: false },
  { name: 'Lessons', href: '/show-jumping-lessons', cta: true },
];

const Header = function () {
  return (
    <header>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-5">
              <div className="relative flex items-center justify-between h-28 ">
                <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex  p-2 rounded-md text-brand-blue-dark hover:text-white hover:bg-brand-blue-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-8 w-8" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-8 w-8" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

                <div className="flex-1 flex items-center justify-end lg:justify-between lg:items-center">
                  <div className="flex-shrink-0 flex items-center pr-5 ">
                    <Link to="/">
                      <StaticImage
                        src="../images/southern-cross-showjumpers.png"
                        quality={95}
                        width={250}
                        loading="eager"
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="Southern Cross Showjumpers"
                      />
                    </Link>
                  </div>
                  <div className="hidden lg:block sm:ml-6">
                    <div className="flex space-x-4 font-roboto ">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          activeClassName={classNames(
                            !item.cta ? 'text-brand-blue-100' : 'text-white'
                          )}
                          className={classNames(
                            item.cta
                              ? 'bg-brand-green-100 text-white'
                              : 'hover:bg-brand-blue-200 hover:text-white',
                            'px-3 py-2 rounded-md sm:text-sm md:text-xl font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current && item.cta
                        ? 'bg-brand-brown-200 text-white'
                        : 'text-brand-blue-100 hover:bg-gray-200 hover:text-gray-900',
                      'block px-3 py-2 rounded-md text-2xl text-center font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
