import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  PhoneIcon,
  MailIcon,
  LocationMarkerIcon,
} from '@heroicons/react/outline';

const Footer = function () {
  return (
    <footer aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <StaticImage
              src="../images/southern-cross-showjumpers.png"
              quality={95}
              height={50}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Southern Cross Showjumpers"
              loading="lazy"
            />
            <p className="text-gray-500 text-base">
              Southern Ontario’s only ‘Luxury Included’ Equestrian facility.
            </p>
          </div>

          <div className="mt-12 grid grid-cols-1 gap-6 xl:mt-0 xl:col-span-2  ">
            <div className="md:grid md:grid-cols-2 md:gap-6 order-1 ">
              <div>
                <h3 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
                  Contact
                </h3>
                <dl className="mt-8 text-lg text-gray-500">
                  <div>
                    <dt className="sr-only">Postal address</dt>
                    <dd className="flex">
                      <LocationMarkerIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <p className="ml-3">
                        1 - 49 Peavinery Rd. Brant, ON N0E 1A0
                      </p>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Phone number</dt>
                    <dd className="flex">
                      <PhoneIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">519-732-1389</span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <MailIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">
                        southerncrossequestrian@outlook.com
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            © {new Date().getFullYear()} Southern Cross Equestrian, Built by
            {` `}
            <a
              href="https://baileytech.ca"
              alt="Website Design &amp; Development"
            >
              Bailey Tech
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
